<template>
    <CCard>
        <CCardHeader class="page-header">
            <strong> {{ $t('common.menu.settings.articles') }}</strong>
            <back-button></back-button>
        </CCardHeader>
        <CCardBody>
            <b-tabs lazy content-class="mt-3">
                <b-tab v-for="(tab,index) in tabs"
                       :key="tab.key"
                       v-if="tab.enabled"
                       :active="activeTab==tab.key"
                >
                    <template slot="title">
                        <font-awesome-icon :icon="tab.icon"></font-awesome-icon>
                        {{ tab.title }}
                        <b-badge v-if="tab.new" href="#" variant="warning">New</b-badge>
                    </template>
                    <component v-bind:is="tab.tab"></component>
                </b-tab>
            </b-tabs>
        </CCardBody>
    </CCard>
</template>

<script>
import {mapGetters} from 'vuex'

const TabConfigurable = () => import('./configurable')

export default {
    name: 'SettingsArticles',
    data() {
        let tabs = [
            {
                'key': 'configurable',
                'title': this.$t('settings.configurable.title'),
                'icon': 'project-diagram',
                'tab': TabConfigurable,
                'enabled': true,
            },
        ]

        return {
            'activeTab': 'configurable',
            'tabs': tabs,
        }
    },
    methods: {},
}
</script>